import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "../assets/footer.sass"

const Footer = () => (
    <div className="footer-component">
            <div className="contact-info">
      <div className="contact-image-wrapper">
        <Image filename="email.png" />
        <a href="mailto:thex.signaturecompany@gmail.com">thex.signaturecompany@gmail.com </a>
      </div>
      {/* <div className="contact-image-wrapper">
        <Image filename="phone.png" />
        <a href="tel:630-596-7712">(630) 596-7712</a>
      </div>
      <div className="contact-image-wrapper">
        <Image filename="location.png" />      
        <a href="https://goo.gl/maps/EowHzCQXwgG3pXYTA">302 S. Lake Street, Aurora, IL 60506</a>
      </div> */}
    </div>  
    <div className="footer-inner" >
    
        <div className="footer footer-buisness-info">
            <div className="footer-logo-container">
                <Link to="/">
                    <Image filename="xsignature.png" />
                </Link>
            </div>
            {/* <p>302 S. Lake Street, Aurora, IL 60506</p>
            <p>(630) 596-7712</p> */}
            <p>thex.signaturecompany@gmail.com</p>
        </div>

        <div className="footer"> 
            <div className="footer-social-links">
                <a href="https://www.instagram.com/x.signed10"><Image filename="instagram.png" /></a>
            </div>
            <div className="footer-social-links">
                <a href="https://www.yelp.com/biz/x-signature-aurora"><Image filename="yelp.png" /></a>
            </div>
            <p>
                XSignature © 2023. All rights reserved.
            </p>
        </div>
    </div>
    </div>
  )

  export default Footer